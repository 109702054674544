/* Contact Form css Start------------ */

.contact-form-container form {
  display: flex;
  flex-direction: column;
}

.contact-form-container input,
.contact-form-container textarea {
  margin-bottom: 15px;
  padding: 8px 10px;
}

.contact-form-container button {
  padding: 10px;
  background-color: #f19a0e;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

.contact-form-container button:hover {
  background-color: #d18811;
}

/* Contact Form css End------------ */
