/* Global CSS / Main-Css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

:root {
  /* --background-color: #f19a0e; */
  /* --background-color: #edbb2ebf; */
  --background-color: #e7b323d1;
  --color: #f19a0e;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: Montserrat, sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
}

.containerAlignSpace {
  width: 80%;
  margin: 0 auto;
}

.Btn {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 20px;
  padding: 12px 40px;
  border: none;
  border-radius: 30px;
  color: #ffffff;
  cursor: pointer;
  background-color: var(--background-color);
  /* border: 1px solid #f19a0e; */
}

.Btn:hover {
  background-color: #d98b0d;
  /* background-color: #da8e14;
  box-shadow: 0 0 0 3px #f19a0e; */
}

button a {
  text-decoration: none;
  color: #fff;
}

#arrowTopBtn {
  transition: all 0.6s;
}

#arrowTopBtn {
  position: fixed;
  bottom: 25px;
  right: 0;
  font-size: 25px;
  padding: 7px 9px;
  padding-top: 0;
  border: 0;
  background: var(--background-color);
  color: #fff;
  border-radius: 50%;
  transform: translateX(100px);
}

#arrowTopBtn:hover {
  box-shadow: 0 0 13px 3px #da8e14;
  background-color: #f1b657;
}

.whatsapp-btn {
  border: none;
  border-radius: 50%;
  background-color: transparent;
  position: fixed;
  bottom: 25px;
  left: 20px;
}

.whatsapp-btn img {
  height: 55px;
  width: 55px;
}

.progressBar {
  position: fixed;
  left: 0;
  right: 0;
  height: 10px;
  background-color: #e7b323d1;
  transform-origin: 0%;
}

/* Media Query-------------------- */

@media screen and (max-width: 1101px) {
  #connectWorldSpace h4 {
    font-size: 20px;
  }
}

@media screen and (min-width: 991px) {
  .offcanvas-body a {
    margin: 0 20px;
  }
}

@media screen and (max-width: 576px) {
  .offcanvas {
    width: 250px !important;
    font-size: 15px;
  }

  .offcanvas-header h5 {
    font-size: 17px;
  }

  .containerAlignSpace {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  #contactSection {
    height: auto;
  }
  #contactDetailsRow > div {
    margin: 10px 0;
  }
}
