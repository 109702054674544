/* Licenses */

.splide__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.splide__pagination li {
  list-style-type: none;
  margin: 0 10px;
}

.splide__arrows {
  display: none;
}

.splide__pagination__page {
    height: 10px;
    width: 10px;
    border: none;
    background-color: var(--background-color);
    border-radius: 50%;
}

.splide__pagination__page:hover {
    background-color: #cb990df1;
}