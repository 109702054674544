/* About Us css Start------------------- */

.about-us-section {
  background-color: var(--background-color);
  color: #fff;
  padding: 30px 0;
}

.about-us-section img {
  width: 100%;
  height: auto;
}

.about-us-section h5 {
  color: var(--background-color);
  font-weight: 700;
}

.aboutMainSpace {
  text-align: center;
  padding: 20px 0;
}

#aboutUsFeatures .feature-column {
  background-color: #fff;
  color: #000000;
  border: 1px solid #fff;
  padding: 30px;
}

.feature-column p {
  margin-top: 32px;
}

#aboutUsFeatures img {
  width: 100%;
  height: 100%;
}

/* Booking Part------ */

.booking-Part {
  padding: 70px 0 30px;
  text-align: left;
  color: #000000;
}

.booking-Part .row .bg-white {
  padding: 40px 25px;
}

.booking-Part button {
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 28px;
  border: none;
  color: #ffffff;
  background-color: #000000;
}

.booking-Part button:hover {
  background-color: var(--background-color);
  color: #fff;
}

/* About Us css End------------------- */
