/* Contact Us css Start------------------- */

.contact-section {
  text-align: center;
  background-image: url("../../../assets/Images/contactBanner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: auto;
  background-size: cover;
  box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.55);
  padding: 50px 0;
  display: flex;
  align-items: center;
}

.contactSpace {
  padding: 20px 0;
  color: #fff;
}

.contact-info {
  width: 75%;
  margin: 0 auto;
}

.contact-info h2 {
  font-size: 50px;
  color: var(--color);
  font-size: 3.4375rem;
  font-weight: 600;
}

.contact-info .contact-divider {
  background: var(--background-color);
  height: 3px;
  width: 150px;
  margin: 20px auto 25px;
  border: 0;
  opacity: 1;
}

.contact-details {
  margin-top: 40px;
}

#contactDetailsRow {
  margin: 0;
  padding: 0;
}

.contact-icon {
  background-color: var(--background-color);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  padding: 15px;
  margin: 25px auto;
}

#contactDetailsRow p {
  margin-top: 20px;
  line-height: 1.8;
}

#contactDetailsRow p a {
  color: #fff;
  font-weight: 700;
}

#contactDetailsRow p a:hover {
  color: var(--color);
}

.contactSpace #contactDetailsRow > div {
  padding: 40px 0;
}

/* Contact Us css End------------------- */
