/* Owners css Start------------------- */

.OwnersMainPart {
  padding: 0;
  margin: 0;
  align-items: center;
}

.OwnersMainPart > div {
  text-align: center;
  padding: 40px 30px;
}

.OwnersMainPart > div img {
  height: 330px;
  width: 330px;
  max-width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.OwnersMainPart .infoChild {
  padding: 40px 30px;
  text-align: justify;
}

.carousel-item img {
  filter: none;
}

.carousel-control-next,
.carousel-control-prev {
  background-color: var(--background-color) !important;
}

.carousel-control-prev {
  left: 10px !important;
}

.carousel-control-next {
  right: 10px !important;
}

.bottom-line {
  content: "";
  position: relative;
  top: 0;
  width: 30px;
  height: 3px;
  background-color: var(--background-color);
  margin: 20px auto;
}

/* Owners css End------------------- */
