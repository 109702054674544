/* Home css start------------------- */

.home-section {
  position: relative;
  background-image: url("../../../assets/Images/portHome.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-position: center center;
  background-size: cover;
  box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.55);
}

.home-section img {
  width: 100%;
  height: 100vh;
}

.home-section .content-container {
  width: 100%;
  padding: 30px 40px;
  text-align: center;
  color: #fff;
  top: 65%;
  transform: translate(0, -50%);
}

.carousel-item img {
  width: 100%;
  height: 100vh;
  filter: brightness(0.7);
}
.carousel button {
  width: 2rem;
  height: 2rem;
  top: 50%;
}

.carousel-control-prev {
  left: 20px !important;
}

.carousel-control-next {
  right: 20px !important;
}

p {
  font-style: italic;
}

.contact-btn {
  border: 1px solid #fff;
  background-color: transparent;
}

.contact-btn:hover {
  border: 1px solid #f19a0e;
  background-color: #f19a0e;
}

.home-section .content-container .main-heading {
  font-size: 40px;
}

@media screen and (max-width: 815px) {
  .home-section .content-container .main-heading {
    font-size: 25px;
    text-wrap: pretty;
  }
}

/* Home css End------------------- */
