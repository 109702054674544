/* Products css Start------------------- */

.product-images-section {
  padding: 20px 10px;
  text-align: center;
  background-color: var(--background-color);
}

.product-images-section img {
  /* width: 481.725px; */
  width: 100%;
  height: 325.413px;
  object-fit: cover;
}

#productGrid .product-item div {
  padding: 5px;
  background-color: #fff;
}

.row > * {
  margin-bottom: 0;
}

.productImageSpace #productGrid h5 {
  background-color: chocolate;
  padding: 5px 0 10px;
  color: #fff;
  text-wrap: wrap;
  margin-bottom: 0;
}

/* All Products css-------- */

#ProductsImgStore {
  /* padding: 30px 0; */
  padding: 80px 0 30px;
}

#ProductsImgStore img {
  /* width: 279.775px; */
  height: 245px;
}

.AllProductsSpace .card-body a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
}

.AllProductsSpace .card-body a svg {
  background: #e7b323d1;
  font-size: 20px;
  padding: 4px;
  border-radius: 50%;
  color: #fff;
  margin: 2px 5px 0 0;
}

.modal-dialog {
  max-width: 600px !important;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.modelDetails {
  width: 100%;
  height: 245px;
  padding: 0 15px;
}

.modelDetails table {
  /* margin: 0 auto; */
  width: 100%;
}

.modelDetails td {
  border: 1px solid #e7b323d1;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .modelDetails {
    padding: 15px;
  }
}

/* Products css End------------------- */
