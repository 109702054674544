/* ConnectWorld css start------------------- */

/* Welcome Space start---- */

.welcomeSpace {
  padding: 20px;
}

.welcomeSpace h2 {
  margin: 13px 0;
}

.welcomeSpace p {
  padding: 10px;
  font-size: 20px;
}

/* Welcome Space end---- */

#connectWorldSpace {
  background-color: var(--background-color);
  padding: 30px 0;
}

#connectWorldSpace .CnWSpace {
  width: 100%;
  padding-top: 20px;
}

.headPart {
  padding: 30px;
  color: #fff;
  text-align: center;
}

.headPart p {
  line-height: 1.6;
  font-style: italic;
  font-size: 1.125rem;
  margin: 15px 0 0;
}

#featureCards {
  text-align: center;
  margin: 0;
}

#featureCards h4 {
  margin: 20px 0 0;
  font-weight: 700;
  word-wrap: break-word;
}

#featureCards p {
  font-size: 1rem;
  font-style: italic;
  margin: 20px 0 0;
  font-weight: 400;
  color: #808080;
  word-wrap: break-word;
}

#featureCards .col > .card {
  transition: 0.4s;
  height: 100%;
  border: none;
  padding: 18px;
}

#connectWorldSpace .row .col .card:hover {
  transform: scale(1.05);
}

.card-img-top {
  height: 100%;
}

.row > * {
  margin-bottom: var(--bs-gutter-y);
}

.row .col div {
  border-radius: 0;
}

#learnMoreButtonSection {
  text-align: center;
  padding: 20px 0 35px;
}

#learnMoreButtonSection button a {
  text-decoration: none;
  color: #fff;
}

/* contactPeraSec------ */

#contactParagraphSection {
  padding: 55px 0;
}

#contactParagraphSection .card {
  border: none;
  border-radius: 0;
}

.InfoPart {
  padding: 50px !important;
  background-color: #e5e5e5;
}

.InfoPart > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 0;
}

.card-text {
  margin-top: 10px;
}

/* connectWorldSpace css End------------------- */
