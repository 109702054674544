/* NavBar css start------------------- */

#navSec {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
}

.navbar-brand img {
  height: 50px;
}

.offcanvas {
  width: 300px !important;
}

.offcanvas-header {
  background-color: #f19a0ea8;
  color: #fff;
}

.offcanvas-body a {
  margin: 10px;
  padding: 10px;
  font-weight: 500;
  border-radius: 7px;
}

.offcanvas-body a:hover {
  background-color: var(--background-color);
  color: #fff;
}

@media screen and (max-width: 991px) {
  .offcanvas-body {
    background-color: bisque;
  }
  .navbar-brand img {
    height: 30px;
  }
}

@media screen and (max-width: 591px) {
  .offcanvas {
    width: 70% !important;
  }
}

/* NavBar css End------------------- */
